import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTModalCard = _resolveComponent("KTModalCard")!
  const _component_KTViewUsersModal = _resolveComponent("KTViewUsersModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_KTModalCard, {
      title: "View Users Modal Example",
      description: "Click on the below buttons to launch <br/>user lists example.",
      image: "/media/illustrations/sketchy-1/10.png",
      "button-text": "View Users",
      "modal-id": "kt_modal_view_users"
    }),
    _createVNode(_component_KTViewUsersModal)
  ], 64))
}